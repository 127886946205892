<div class="btnHolder">
    <button  class="greenBtnCls float-right mr-3 my-3" *ngIf="userInfo.userPrincipalName?.toLowerCase()?.trim() === 'com_gsws@apgsws.onmicrosoft.com'"  (click)="goToReshedule()">Reschedule Welfare Schemes</button>
</div>
<div class="report-section">
    <label>Please select the Report Type: </label>
    <select class="selectCls" [(ngModel)]="reportType" placeholder="Select" [ngClass]="{ 'is-invalid': submitted && reportType === null}">
        <option [ngValue]="null" disabled>Select</option>
        <option  value="servicesDashboard">AP SEVA Services Dashboard</option>
        <option  value="paymentDashboard">AP SEVA Payment Dashboard</option>
        <option  value="analyticsDashboard">Analytics Dashboard</option>
        <option  value="subSLADashboard">Sub SLA Dashboard</option>
        <!-- <option  value="paymentReport">Payment Report</option> -->
        <option  value="OTSPaymentReport">OTS Payment Report</option>
        <option  value="ArogyaSurakshaReport">Aarogya Suraksha 2.0 Scheduling Status Reports</option>
        <option  value="WelfareSchemeProgramReport">Unveiling Of Welfare Schemes Display Boards-Program Details Report</option>
        <option  value="ArogyaSurakshaDashboard">Aarogya Suraksha 2.0 Camp Status Report</option>
        <option  value="mutForCorrections">Mutation For Corrections</option>
        <option  value="mutForTransactions">Mutation For Transactions</option>
        <option  value="failedTransactions">Failed Transactions</option>
        <!-- <option  value="jaganAnnaSuraksha">Jagananna Suraksha Report</option> -->
        <!-- <option  value="jaganAnnaDashboard">Jagananna Suraksha Dashboard</option> -->
        <!-- <option  value="teamScheduleReport">Team Schedule Report</option> -->
        <!-- <option  value="campStatusReport">Jagananna Suraksha Camp Status Report</option> -->
        <option  value="tokenGenerationReport">Aarogya Suraksha 2.0 Token Generation Report</option>
        <option  value="pallekupodam">Unveiling of Welfare Schemes  Display Boards - Schedule</option>
        <option  value="WelfareSchemeProgramReport">Unveiling Of Welfare Schemes Display Boards-Program Details Report</option>
        <option  value="gswsDynamicSurveyReport">GSWS Dynamic Survey Portal</option>
        <option  value="passbookServiceReport">Passbook Service Report</option>
        <option  value="pensionReport">Pension 6 Step Parameter Update Report</option>
        <option  value="PANCollectionReport">Monitoring Dashboard for PAN Collection Report</option>
        <option  value="UtilizationCertificateReport">Utilization Certificate Report</option>
        <option  value="AadharChallanGenerationReport">Aadhar Challan Generation Report</option>
        <option  value="goodGovernmentReport">ఇది మంచి ప్రభుత్వం క్యాంపెయిన్ రిపోర్ట్</option>
        <option  value="volunteerappreciationreport">Volunteer Appreciation Program Details Report</option>
        <option  value="DayNodalOfficerIECDistributionReport">VBSY Day Nodal Officer IEC Distribution Report</option>
        <option  value="districtlevelstationarybalanceReport">VBSY district level stationary balance report</option>
        <option  value="secretariatPANUpdatedReport">Secretariat PAN Updated Report</option>
        <option  value="secretariatRevenueVillageMappingReport">Secretariat-Revenue Village Mapping Report</option>
        <option  value="SuomotoCasteVerificationReport">Suomoto Caste Verification Report</option>
</select>
    <div class="selectError" *ngIf="submitted && reportType === null">Please select Report Type </div>
    <div class="text-center mt-3">
        <button class="btn btnSearch btn-primary mx-auto d-block" (click)="getReport()">Get Report</button>
    </div>
</div>

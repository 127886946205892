import { APP_INITIALIZER, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgbActiveModal, NgbModal, NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from '../shared/shared.module';
import { DashboardComponent } from './dashboard/dashboard.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { MsalModule, MsalRedirectComponent, MsalInterceptor } from '@azure/msal-angular';
import { PublicClientApplication, InteractionType } from '@azure/msal-browser';
import { PaymentGatewayComponent } from './payment-gateway/payment-gateway.component';
import { ViewTransactionsComponent } from './view-transactions/view-transactions.component';
import { HeaderComponent } from './header/header.component';
import { LoaderComponent } from './loader/loader.component';
import { LoaderService } from '../shared/loader.service';
import { LoaderInterceptor } from '../shared/loader-interceptor.service';
import { LocationStrategy, HashLocationStrategy } from '@angular/common';
import { CommonDashboardComponent } from './common-dashboard/common-dashboard.component';
import { HomeComponent } from './home/home.component';
import { FooterComponent  } from './footer/footer.component';
import {HomeHeaderComponent } from './home-header/home-header.component';
import { SwiperModule } from 'ngx-swiper-wrapper';
import { SWIPER_CONFIG } from 'ngx-swiper-wrapper';
import { SwiperConfigInterface } from 'ngx-swiper-wrapper';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { RevenueViewModule } from './revenue-view/revenue-view.module';
import { EMudraComponent } from './e-mudra/e-mudra.component';
import { PaymentReceiptComponent } from './payment-receipt/payment-receipt.component';
import { environment } from './../environments/environment';
import { PaymentResponseComponent } from './payment-response/payment-response.component';
import { ErrorPageNotFoundComponent } from './error-page-not-found/error-page-not-found.component';
import { TransactionStatusComponent } from './transaction-status/transaction-status.component';
import { ApplicationPreviewComponent } from './application-preview/application-preview.component';
import { EnvironmentService } from '../shared/environment.service';
import { CivilsuppliesViewModule } from './civil-supplies/civilsupplies-view/civilsupplies-view.module';
import { PrintSixStepProformaComponent } from './print-six-step-proforma/print-six-step-proforma.component';
import { AboutUsComponent } from './about-us/about-us.component';
import { PensionViewModule } from './pension-ui/pension-view/pension-view.module';
import { ChallanGenerationComponent } from './payment-challan-generation/challan-generation.component';
import { NgxQRCodeModule } from '@techiediaries/ngx-qrcode';
import { PaymentChallanHistoryComponent } from './payment-challan-history/payment-challan-history.component';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { NgxOtpInputModule } from 'ngx-otp-input';
import { MaudViewModule } from './maud/maud-view/maud-view.module';
import { NgMultiSelectDropDownModule } from 'ng-multiselect-dropdown';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { MDBBootstrapModule } from 'angular-bootstrap-md';
import { ReportsComponent } from './reports/reports.component';
import { ExcelReportComponent } from './excel-report/excel-report.component';
import { DocumentListComponent } from './document-list/document-list.component';
import { NgxBarcodeModule } from 'ngx-barcode';
import { BackButtonDisableModule } from 'angular-disable-browser-back-button';
import { SignatureUploadComponent } from './signature-upload/signature-upload.component';
import { CspServiceReportComponent } from './csp-service-report/csp-service-report.component';
import { LaminationComponent } from './lamination/lamination.component';
import { LaminationViewComponent } from './lamination-view/lamination-view.component';
import { PaymentReportComponent } from './payment-report/payment-report.component';
import{OtsPaymentReportComponent} from './ots-payment-report/ots-payment-report.component'
import { HealthViewModule } from './health-view/health-view.module';
import { HealthModule } from './health/health.module';
import{LabourRegistrationModule} from './labour-registration/labour-registration.module';
import { ReconsulationPaymentStatusComponent } from './reconsulation-payment-status/reconsulation-payment-status.component';
import { MutationForCorrectionsComponent } from './mutation-for-corrections/mutation-for-corrections.component';
import { ExternalPaymentGatewayComponent } from './external-payment-gateway/external-payment-gateway.component';
import { ExternalPaymentResponseComponent } from './external-payment-response/external-payment-response.component';
import { ExternalPaymentComponent } from './external-payment/external-payment.component';
import { FailedtransactionsComponent } from './failedtransactions/failedtransactions.component';
import { MutationForTransactionsComponent } from './mutation-for-transactions/mutation-for-transactions.component';
import { CheckoutModule } from '../../angular-checkout-js/src/lib/checkout.module';
import { CheckoutService } from '../../angular-checkout-js/src/lib/services/checkout.service';
import { HttpErrorInterceptor } from '../shared/http-error-interceptor';
import { PaymentResponsePaytmComponent } from './payment-response-paytm/payment-response-paytm.component';
import { RevenueShareReportComponent } from './revenue-share-report/revenue-share-report.component';
import { RegistrationViewModule } from './registration-view/registration-view.module';
import { PrintServiceComponent } from './print-service/print-service.component';
import { PrintServiceViewComponent } from './print-service-view/print-service-view.component';
import { HumanResourcesModule } from './human-resources/human-resources.module';
import { MinorityWelfareModule } from './minority-welfare/minority-welfare.module';
import { CertificateDownloadComponent } from './certificate-download/certificate-download.component';
import { AddressCertificateForAadharEnrollmentComponent } from './address-certificate-for-aadhar-enrollment/address-certificate-for-aadhar-enrollment.component';
import { AddressCertificateForAadharEnrollmentViewComponent } from './address-certificate-for-aadhar-enrollment-view/address-certificate-for-aadhar-enrollment-view.component';
import { ChallanMappingComponent } from './challan-mapping/challan-mapping.component'
import { CspPaymentReportComponent } from './csp-payment-report/csp-payment-report.component'
import { PaymentReconciliationComponent } from './payment-reconciliation/payment-reconciliation.component';
import { PaymentReconciliationApprovalComponent } from './payment-reconciliation-approval/payment-reconciliation-approval.component';
import { PaymentReconciliationReportComponent } from './payment-reconciliation-report/payment-reconciliation-report.component';
import { CollectionPaymentReportComponent } from './collection-payment-report/collection-payment-report.component';
import { TeamFormationComponent } from './team-formation/team-formation.component';
import { TeamScheduleComponent } from './team-schedule/team-schedule.component';
import { TokenRegistrationComponent } from './token-registration/token-registration.component';
import { TokenRegistrationViewComponent } from './token-registrationy-view/token-registration-view.component';
import { JaganannaSurakshaReportComponent } from './jagananna-suraksha-report/jagananna-suraksha-report.component';
import { JaganannaScheduleSearchComponent } from './jagananna-schedule-search/jagananna-schedule-search.component';
import { ScheduleReportComponent } from './schedule-report/schedule-report.component';
import { JaganannaSurakshaDashboardComponent } from './jagananna-suraksha-dashboard/jagananna-suraksha-dashboard.component';
import { JaganannaSurakshaCampStatusReportComponent } from './jagananna-suraksha-camp-status-report/jagananna-suraksha-camp-status-report.component';
import { RevokeProvisionComponent } from './revoke-provision/revoke-provision.component';
import { RevokeWorklistComponent } from './revoke-worklist/revoke-worklist.component';
import { BasicPopupChangesComponent } from './basic-popup-changes/basic-popup-changes.component';
import { CommonService } from 'src/shared/common.service';
import { PassbookRelatedServiceReportComponent } from './passbook-related-service-report/passbook-related-service-report.component';
import { PassbookRelatedServiceStatusReportComponent } from './passbook-related-service-status-report/passbook-related-service-status-report.component';
// import { ErrorApplicationReportComponent } from './error-application-report/error-application-report.component';
import { PrintCertificatePopupComponent } from './print-certificate-popup/print-certificate-popup.component';
import { SaveDesignationsPositionsComponent } from './save-designations-positions/save-designations-positions.component';
import { GswsDynamicSurveyReportComponent } from './gsws-dynamic-survey-report/gsws-dynamic-survey-report.component';
import { AarogyaSurakshaTeamFormComponent } from './aarogya-suraksha-team-form/aarogya-suraksha-team-form.component';
import { AarogyaSurakshaDoctorAssignDhmoComponent } from './aarogya-suraksha-doctor-assign-dhmo/aarogya-suraksha-doctor-assign-dhmo.component';
import { AarogyaTeamScheduleComponent } from './aarogya-team-schedule/aarogya-team-schedule.component';
import { ArogyaSurakshaReportComponent } from './arogya-suraksha-report/arogya-suraksha-report.component';
import { AarogyaSurakshaCampDetailsComponent } from './aarogya-suraksha-camp-details/aarogya-suraksha-camp-details.component';
import { PatientBankDetailsPopupComponent } from './patient-bank-details-popup/patient-bank-details-popup.component';
import { ArogyaSurakshaDashboardComponent } from './arogya-suraksha-dashboard/arogya-suraksha-dashboard.component';
import { JaganannaSurakshaTokenGenerationReportComponent } from './jagananna-suraksha-token-generation-report/jagananna-suraksha-token-generation-report.component';
import { AarogyaSurakshaTokenGenerationComponent } from './aarogya-suraksha-token-generation/aarogya-suraksha-token-generation.component';
import { PallekuPodamComponent } from './palleku-podam/palleku-podam.component';
import { UnveilingOfWelfareSchemesDisplayComponent } from './unveiling-of-welfare-schemes-display/unveiling-of-welfare-schemes-display.component';
import { WelfareSchemeProgramReportComponent } from './welfare-scheme-program-report/welfare-scheme-program-report.component';
import { ResheduleWelfareSchemesComponent } from './reshedule-welfare-schemes/reshedule-welfare-schemes.component';
import { PassbookServiceReportComponent } from './passbook-service-report/passbook-service-report.component';
import { AadhaarChallanGenerationComponent } from './aadhaar-challan-generation/aadhaar-challan-generation.component';
import { PensionReportComponent } from './pension-report/pension-report.component';
import { MonitoringDashboardForPANCollectionComponent } from './monitoring-dashboard-for-pan-collection/monitoring-dashboard-for-pan-collection.component';
import { PanDetailsCollectionComponent } from './pan-details-collection/pan-details-collection.component';
import { ViksitBharatDistributionComponent } from './viksit-bharat-distribution/viksit-bharat-distribution.component';
import { VBSYInventoryComponent } from './vbsy-inventory/vbsy-inventory.component';
import { ViksitBharatDistributionReportComponent } from './viksit-bharat-distribution-report/viksit-bharat-distribution-report.component';
import { ViksitBharatReportComponent } from './viksit-bharat-report/viksit-bharat-report.component';
import { CommonDashboardLinksComponent } from './common-dashboard-links/common-dashboard-links.component';
import { VillageMappingInTahsildharComponent } from './village-mapping-in-tahsildhar/village-mapping-in-tahsildhar.component';
import { UtilizationCertificateComponent } from './utilization-certificate/utilization-certificate.component';
import { UCReportComponent } from './uc-report/uc-report.component';
import { PensionsReportComponent } from './pensions-report/pensions-report.component';
import { SecreteriateRevenueVillageMappingReportComponent } from './secreteriate-revenue-village-mapping-report/secreteriate-revenue-village-mapping-report.component';
import { SecreteriatePanUpdatedReportComponent } from './secreteriate-pan-updated-report/secreteriate-pan-updated-report.component';
import { SadaremprintCertificateComponent } from './sadaremprint-certificate/sadaremprint-certificate.component';
import { SadaremPrintCertificateViewComponent } from './sadarem-print-certificate-view/sadarem-print-certificate-view.component';
import { VolunteerAppreciationReportComponent } from './volunteer-appreciation-report/volunteer-appreciation-report.component';
import { VolunteerAppreciationProgramDetailsComponent } from './volunteer-appreciation-program-details/volunteer-appreciation-program-details.component';
import { AadharChallanGenerationReportComponent } from './aadhar-challan-generation-report/aadhar-challan-generation-report.component';
import { SearchScreenCasteComponent } from './search-screen-caste/search-screen-caste.component';
import { LandAssignmentToSoldiersComponent } from './revenue/land-assignment-to-soldiers/land-assignment-to-soldiers.component';
import { SuomotoPendingApplicationsReportComponent } from './suomoto-pending-applications-report/suomoto-pending-applications-report.component';
import { CommonFormViewComponent } from 'src/shared/common-form-view/common-form-view.component';
import { IntegratedCertificateViewComponent } from './revenue-view/integrated-certificate-view/integrated-certificate-view.component';
import { GswsFeedbackComponent } from './gsws-feedback/gsws-feedback.component';
import { MeesevaFeedbackComponent } from './meeseva-feedback/meeseva-feedback.component';
import { SuomotoCasteVerificationReportComponent } from './suomoto-caste-verification-report/suomoto-caste-verification-report.component';
import { FeedbackStatusComponent } from './feedback-status/feedback-status.component';
import { GoodgovernmentreportComponent } from './goodgovernmentreport/goodgovernmentreport.component';
import { GoodGovernmentComponent } from './good-government/good-government.component';


const DEFAULT_SWIPER_CONFIG: SwiperConfigInterface = {
  direction: 'horizontal',
  slidesPerView: 'auto'
};

const isIE = window.navigator.userAgent.indexOf('MSIE ') > -1 || window.navigator.userAgent.indexOf('Trident/') > -1
const hostname = window && window.location && window.location.hostname;
    let clientId: any = '';
    let apiClientId: any = '';
    let baseUrl = '';
    let inboundUrl = '';
    if (/^.*localhost.*/.test(hostname) || /^gswswebdev.z29.web.core.windows.net/.test(hostname) || /^apgv.vswsonline.ap.gov.in/.test(hostname) 
    || /^stgvswsdevappci01.z29.web.core.windows.net/.test(hostname) || /^dev.vswsonline.ap.gov.in/.test(hostname)) {
     //local and dev
     
     clientId = '30d609bd-caee-4198-a7b4-8353efca63a6';//'b9608766-c28c-4966-8f61-f458ce508fa6';
     apiClientId = 'api://dd1dd082-b489-4aa7-ad10-69f5699be335/access-as-user';//'api://433a756e-a546-48ca-920e-c5fdab44d809/access-as-user';
     
     baseUrl = 'https://apidev.vswsonline.ap.gov.in/';
     inboundUrl = 'https://apidev.vswsonline.ap.gov.in/';
    } else if (/^uat.vswsonline.ap.gov.in/.test(hostname)) {
      //UAT
      clientId = '9358bc12-0b65-43b9-a4d7-68210b3805c0'; 
      apiClientId = 'api://6bfb4f0c-03ed-41c5-9810-bacfe4fd31d3/access-as-user';
      baseUrl = 'https://apiuat.vswsonline.ap.gov.in/';
      inboundUrl = 'https://apiuat.vswsonline.ap.gov.in/';
    } else if (/^vswsonline.ap.gov.in/.test(hostname)) {
      //PROD
      clientId = '26eba3e6-0f1d-4436-a926-080ff94bb1fd'; 
      apiClientId = 'api://a30a123a-8a12-4429-a452-13f563487e8b/access-as-user'; 
      baseUrl = 'https://api.vswsonline.ap.gov.in/';
      inboundUrl = 'https://api.vswsonline.ap.gov.in/';
    }else {
      console.warn(`Cannot find environment for host name ${hostname}`);
    }
@NgModule({
  declarations: [
    AppComponent,
    DashboardComponent,
    PaymentGatewayComponent,
    ViewTransactionsComponent,
    HeaderComponent,
    LoaderComponent,
    CommonDashboardComponent,
    CommonDashboardLinksComponent,
    HomeComponent,    
    FooterComponent,
    HomeHeaderComponent,
    EMudraComponent,
    PaymentReceiptComponent,
    PaymentResponseComponent,
    ErrorPageNotFoundComponent,
    TransactionStatusComponent,
    ApplicationPreviewComponent,
    PrintSixStepProformaComponent,
    AboutUsComponent,
    ChallanGenerationComponent,
    PaymentReconciliationComponent,
    PaymentReconciliationApprovalComponent,
    PaymentReconciliationReportComponent,
    PaymentChallanHistoryComponent,
    ReportsComponent,
    ExcelReportComponent,
    DocumentListComponent,
    SignatureUploadComponent,
    CspServiceReportComponent,
    LaminationComponent,
    LaminationViewComponent,
    PaymentReportComponent,
    ReconsulationPaymentStatusComponent,
    MutationForCorrectionsComponent,
    ExternalPaymentGatewayComponent,
    ExternalPaymentResponseComponent,
    ExternalPaymentComponent,
    FailedtransactionsComponent,
    MutationForTransactionsComponent,
    OtsPaymentReportComponent,
    PaymentResponsePaytmComponent,
    RevenueShareReportComponent,
    PrintServiceComponent,
    PrintServiceViewComponent,
    CertificateDownloadComponent,
    AddressCertificateForAadharEnrollmentComponent,
    AddressCertificateForAadharEnrollmentViewComponent,
    ChallanMappingComponent,
    CspPaymentReportComponent,
    CollectionPaymentReportComponent,
    TeamFormationComponent,
    TeamScheduleComponent,
    AarogyaTeamScheduleComponent,
    TokenRegistrationComponent,
    TokenRegistrationViewComponent,
    JaganannaScheduleSearchComponent,
    JaganannaSurakshaReportComponent,
    ScheduleReportComponent,
    JaganannaSurakshaDashboardComponent,
    JaganannaSurakshaCampStatusReportComponent,
    RevokeProvisionComponent,
    RevokeWorklistComponent,
    BasicPopupChangesComponent,
    PassbookRelatedServiceReportComponent,
    PassbookRelatedServiceStatusReportComponent,
    // ErrorApplicationReportComponent,
    PrintCertificatePopupComponent,
    SaveDesignationsPositionsComponent,
    GswsDynamicSurveyReportComponent,
    AarogyaSurakshaTeamFormComponent,
    PatientBankDetailsPopupComponent,
    AarogyaSurakshaDoctorAssignDhmoComponent,
    ArogyaSurakshaReportComponent,
    AarogyaSurakshaCampDetailsComponent,
    ArogyaSurakshaDashboardComponent,
    JaganannaSurakshaTokenGenerationReportComponent,
    AarogyaSurakshaTokenGenerationComponent,
    PallekuPodamComponent,
    UnveilingOfWelfareSchemesDisplayComponent,
    WelfareSchemeProgramReportComponent,
    ResheduleWelfareSchemesComponent,
    PassbookServiceReportComponent,
    AadhaarChallanGenerationComponent,
    PensionReportComponent,
    MonitoringDashboardForPANCollectionComponent,
    PanDetailsCollectionComponent,
    ViksitBharatDistributionComponent,
    VBSYInventoryComponent,
    ViksitBharatDistributionReportComponent,
    ViksitBharatReportComponent,
    VillageMappingInTahsildharComponent,
    UtilizationCertificateComponent,
    UCReportComponent,
    PensionsReportComponent,
    SecreteriateRevenueVillageMappingReportComponent,
    SecreteriatePanUpdatedReportComponent,
    SadaremprintCertificateComponent,
    SadaremPrintCertificateViewComponent,
    VolunteerAppreciationReportComponent,
    VolunteerAppreciationProgramDetailsComponent,
    AadharChallanGenerationReportComponent,
    SearchScreenCasteComponent,
    LandAssignmentToSoldiersComponent,
    SuomotoPendingApplicationsReportComponent,
    GswsFeedbackComponent,
    MeesevaFeedbackComponent,
    SuomotoCasteVerificationReportComponent,
    FeedbackStatusComponent,
    GoodgovernmentreportComponent,
    GoodGovernmentComponent
  ],
  exports:[NgxOtpInputModule],
  imports: [
    BrowserModule,
    FontAwesomeModule,
    CommonModule,
    NgbModule,
    FormsModule,
    ReactiveFormsModule,
    AppRoutingModule,
    SharedModule,
    HttpClientModule,
    NgxExtendedPdfViewerModule,
    NgbModule,
    SwiperModule,
    RevenueViewModule,
    RegistrationViewModule,
    CivilsuppliesViewModule,
    PensionViewModule,
    MaudViewModule,
    HealthViewModule,
    HealthModule,
    LabourRegistrationModule,
    NgxQRCodeModule,
    NgxOtpInputModule,
    BrowserAnimationsModule,
    CarouselModule,
    NgxBarcodeModule,
    HumanResourcesModule,
    MinorityWelfareModule,
    NgIdleKeepaliveModule.forRoot(),
    NgMultiSelectDropDownModule.forRoot(),
    BackButtonDisableModule.forRoot({
      preserveScrollPosition: true
    }),
    MDBBootstrapModule.forRoot(),
    MsalModule.forRoot( new PublicClientApplication({
      auth: {
        clientId: clientId, //'b24a7ecc-9d57-465e-8c17-8fb9b21db911',//'b9608766-c28c-4966-8f61-f458ce508fa6', // This is your client ID
        authority: 'https://login.microsoftonline.com/27f010d0-96ad-4ca4-b62d-ae78a4a2a430',//'https://ApSevaPortal.b2clogin.com/ApSevaPortal.onmicrosoft.com/B2C_1_Citizen_Sign.up_sign.in',// // This is your tenant ID
        redirectUri: location.protocol+'//'+location.host,
        //knownAuthorities: ['https://ApSevaPortal.b2clogin.com']
      },
      cache: {
        cacheLocation: 'localStorage',
        storeAuthStateInCookie: isIE, // Set to true for Internet Explorer 11
      }
    }), {
      interactionType: InteractionType.Redirect, // MSAL Guard Configuration
      authRequest: {
        scopes: ['user.read']//['offline_access','openid']//
      }
  }, {
    interactionType: InteractionType.Redirect, // MSAL Interceptor Configuration
    protectedResourceMap: new Map([ 
        ['https://graph.microsoft.com/v1.0/me', ['user.read']],
        // [environment.revenue + '*', ['api://433a756e-a546-48ca-920e-c5fdab44d809/access-as-user']],
        // [environment.citizenInfo + '*', ['api://433a756e-a546-48ca-920e-c5fdab44d809/access-as-user']],
        // [environment.fileUpload + '*',['api://433a756e-a546-48ca-920e-c5fdab44d809/access-as-user']],
        // [environment.worklist + '*',['api://433a756e-a546-48ca-920e-c5fdab44d809/access-as-user']],
        // [environment.pdfGenerator + '*',['api://433a756e-a546-48ca-920e-c5fdab44d809/access-as-user']],
        // [environment.weblandAPI + '*', ['api://433a756e-a546-48ca-920e-c5fdab44d809/access-as-user']],
        // [environment.maudServices + '*', ['api://433a756e-a546-48ca-920e-c5fdab44d809/access-as-user']],
        // [environment.civilSupplies + '*', ['api://433a756e-a546-48ca-920e-c5fdab44d809/access-as-user']],
        // [environment.energyServices + '*', ['api://433a756e-a546-48ca-920e-c5fdab44d809/access-as-user']],
        // [environment.citizenInfoFromGSWS + '*', ['api://433a756e-a546-48ca-920e-c5fdab44d809/access-as-user']],
        [baseUrl+ 'pdfgen/api/PdfGenerate/DownloadCertificateNoAuth?CertificateId=' + '*', null],
        [baseUrl+ 'revenue/revenue/Requisition/GenerateCertificateDataNoAuth' + '*', null],
        [baseUrl+ 'pdfgen/api/PdfGenerate/GenerateCertificateNoAuth?CertificateId=' + '*', null],
        [baseUrl+ 'reports/api/DashBoard/GetDashBoardApplicationDetails' + '*', null],
        [baseUrl+ 'paymentservice/paymentservice/ExternalPaymentService/GetCheckSum_External' + '*', null],
        [baseUrl+ 'paymentservice/paymentservice/ExternalPaymentService/SaveBillDeskResponse_External' + '*', null],
        [baseUrl+ 'paymentservice/paymentservice/ExternalPaymentService/SaveExternalPaymentAllRequest' + '*', null],
        [baseUrl+ 'paymentservice/paymentservice/ExternalPaymentService/VerifyExternalPaymentChecksum' + '*', null],
        [baseUrl+ 'paymentservice/paymentservice/ExternalPaymentService/AESDecryptMesevaRequest' + '*', null],
        [baseUrl+ 'paymentservice/paymentservice/ExternalPaymentService/AESEncryptMesevaRequest' + '*', null],
        [baseUrl+ 'reports/api/CSPServiceDashboard/*', null],
        [baseUrl+ 'reports/api/IdhiManchiPrabutvamSurveyDetails/GetIdhiManchiPrabutvamSurveyData*', null],
        [baseUrl+ 'exmaud/property/propertyOwnerDetails', null],
        [inboundUrl+ 'uatwebland/*', null],
        [baseUrl+ 'PDI/*', null],
        [baseUrl+ 'BHP/*', null],
        [baseUrl+ 'workflow/api/UserDetails/GetSecretariatWiseDesignations?SecretariatCode=' + '*', null],
        [baseUrl+ 'workflow/api/UserDetails/SaveSecretariatWiseRoleDetails', null],
        // [inboundUrl+ 'uatwebland/FinalRORtest/*', null],
        // [inboundUrl+ 'uatwebland/FinalRORTest/*', null],
        [baseUrl+ 'revenue/Common/AESEncryptNoAuth?RawValue=' + '*', null],
        [baseUrl+ 'reports/api/DashBoard/GetApplicationsDetailsByUIDPost' + '*', null],
        [baseUrl+ 'reports/api/DashBoard/GetTopTenServices', null],
        [baseUrl+ 'revenue/revenue/JaganannaSuraksha/GetJSVenueScheduleDetailsByAadharSecCode?SecCode='+ '*', null],
        [baseUrl+ 'gramawardsachivalaya/HouseholdDetailsbyUID', null],
        [baseUrl+ 'reports/api/JASReport/GetDistrict', null],
        [baseUrl+ 'reports/api/JASReport/GetMandalByDistrictRuralUrban', null],
        [baseUrl+ 'reports/api/JASReport/GetVHCByMandal?MandalCode=', null],
        [baseUrl+ 'reports/api/JASReport/GetVHCSecretariatByMandal?MandalCode=', null],
        [baseUrl+ 'HealthandFamily/HealthAndFamily/JaganannaAarogyaSuraksha/GetJASTeamDetailsByVHCScheduledDate'+ '*', null],
        [baseUrl+ 'exsada'+ '*', null],
        [baseUrl+ 'exrofr'+ '*', null],
        [baseUrl+ 'GSWSCITIZEN/Captcha'+ '*', null],
        [baseUrl+ 'pdfgen/api/WhatsappReport/DownloadReportForWhatsapp?FileName=' + '*', null],
        [baseUrl+ 'HealthandFamily/HealthAndFamily/SadaremService/CheckSadaremPaymentDetails', null],
        [baseUrl+ 'exsadram/SADAREMCERT/rest/Print/getCertificateandIDCardForPrint', null],
        [baseUrl+ 'GSWSCITIZEN/CitizenDetails/CitizenSurveyData', null],
        [baseUrl+ 'GSWSCITIZEN/CitizenDetails/InsertCitizenSurveyData', null],
        [baseUrl+ 'GSWSCITIZEN/CitizenDetails/MeesevaCitizenSurveyData', null],
        [baseUrl+ 'GSWSCITIZEN/CitizenDetails/InsertMeesevaCitizenSurveyData', null],
       // [baseUrl+ 'file-upload/api/FileStorage/GetFile?BlobRef='+ '*', null],
       // [baseUrl+ 'revenue/revenue/Requisition/GetById?DepartmentCode='+ '*', null],
        [baseUrl + '*', [apiClientId]],
        [inboundUrl + '*', [apiClientId]]
    ])
  }),
  CheckoutModule
  ],
  providers: [{
    provide: HTTP_INTERCEPTORS,
    useClass: MsalInterceptor,
    multi: true
  },
  LoaderService,
  CommonService,
  CheckoutService,
  {
    provide: HTTP_INTERCEPTORS,
    useClass: LoaderInterceptor,
    multi: true
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: HttpErrorInterceptor,
    multi: true
  },
  {provide: LocationStrategy, useClass: HashLocationStrategy} ,
  {
    provide: SWIPER_CONFIG,
    useValue: DEFAULT_SWIPER_CONFIG
  },
  {
    provide: APP_INITIALIZER,
    useFactory: (envService: EnvironmentService) => () => envService.init(),
    deps: [EnvironmentService],
    multi: true
  },
  NgbActiveModal,
  CommonFormViewComponent,
  IntegratedCertificateViewComponent
],
  bootstrap: [AppComponent, MsalRedirectComponent]
})
export class AppModule { }
